import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import quizzConsumption from './quizz-consumption.json';
import { EConsumption } from 'enum/user';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import QuizzQuestion from 'component/quizz-question/QuizzQuestion';
import QuizzResults from 'component/quizz-results/QuizzResults';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import './ActivityQuizz.scss';

interface answerState {
  [key: number]: number;
}

// @ToDo : quizz by consumption adjustment for vaper
const ActivityQuizz = (): React.ReactElement => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [quizzState, setQuizzState] = useState<answerState>({});
  const [isContinueEnabled, setContinueEnabled] = useState<boolean>(false);
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const quizz = userConsumptionType === EConsumption.VAPER ? quizzConsumption.vaper : quizzConsumption.smoker;
  const isOnQuizzSection: boolean = currentQuestion < quizz.question.length;

  const updateQuizzState = (index: number, answer: number): void => {
    const currentState: answerState = quizzState;
    currentState[index] = answer;
    setContinueEnabled(true);
    setQuizzState(currentState);
  };

  const onContinueButton = (): void => {
    if (isOnQuizzSection) {
      const isQuizzIsFinished: boolean = quizz.question.length > Object.keys(quizzState).length;
      if (isQuizzIsFinished) {
        setContinueEnabled(false);
      }
      setCurrentQuestion(currentQuestion + 1);
    } else {
      sceneInteraction.goToBarScreen(true);
    }
  };

  return (
    <div className="activity-quizz">
      <div className="activity-quizz__wrapper">
        <SwitchTransition>
          <CSSTransition
            key={currentQuestion}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames="fade"
          >
            {isOnQuizzSection ? (
              <QuizzQuestion
                {...quizz.question[currentQuestion]}
                onSubmitAnswer={(answerIndex) => updateQuizzState(currentQuestion, answerIndex)}
              />
            ) : (
              <QuizzResults answerState={quizzState} results={quizz.results} />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      {isOnQuizzSection ? (
        <ActivitySplashAction
          backButtonLabel="Question précédente"
          isBackDisabled={currentQuestion < 1 || !isOnQuizzSection}
          onBackButton={() => setCurrentQuestion(currentQuestion - 1)}
          continueButtonLabel="Suivant"
          isContinueDisabled={!isContinueEnabled}
          onContinueButton={() => onContinueButton()}
        />
      ) : (
        <ActivitySplashAction
          backButtonLabel="Retour"
          isBackDisabled
          continueButtonLabel="Continuer"
          onContinueButton={() => onContinueButton()}
        />
      )}
    </div>
  );
};
export default ActivityQuizz;
