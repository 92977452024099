import React, { useState } from 'react';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import { ScreenContestEndWEBP, SurveyWinSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import { EScene } from 'enum/scene';
import Button from 'component/button/Button';
import ScreenEnd from 'component/screen-end/ScreenEnd';
import { EButtonColor } from 'enum/button';
import './ScreenContestEnding.scss';

/**
 * The main screen to display for the cocktail list activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenContestEnding = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState(true);
  const currentScene: EScene = sceneInteraction.getBarScene();

  const goToNextStep: () => void = (): void => {
    if (isSplashScreen) {
      setHasSplashScreen(false);
    }
  };
  const goToPrevStep: () => void = (): void => {
    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(true);
    }
  };

  const today = new Date().getTime();
  const validUntil = new Date('2024-03-07').getTime();
  const isSurvey: boolean = process.env.REACT_APP_ENV === 'survey' && validUntil > today;
  let message =
    'Tu nous quittes déjà? Tu peux revenir en arrière pour retourner dans le bar et continuer ta visite ou quitter le bar virtuel pour commencer le Défi Nico-Bar sur Messenger dès maintenant!';

  if (isSurvey) {
    message =
      'Ha non tu nous quittes? Tu sais que tu peux revenir nous voir au bar virtuel aussi souvent que tu veux! Mais juste avant que tu partes, si ça te tente de répondre à notre petit sondage sur le bar virtuel, tu pourrais courir la chance de gagner un des 3 prix de 150$ en argent. As-tu 5-6 minutes?';
  }
  return (
    <div className="screen-contest-ending">
      {isSplashScreen ? (
        <ActivitySplashScreen
          title="Le Défi Nico-Bar"
          description={
            currentScene === EScene.END
              ? `Super! Merci pour ta visite dans notre bar virtuel! On t'invite à commencer le Défi Nico-Bar! <br />On te redirigera vers Messenger pour la suite du Défi.`
              : message
          }
          imageSrc={isSurvey ? SurveyWinSVG : ScreenContestEndWEBP}
        >
          <div className="screen-contest-ending__buttons">
            <Button
              className="screen-contest-ending__button"
              onClick={goToPrevStep}
              label="Revenir dans le bar"
              isStyled
              backgroundColor={EButtonColor.OUTLINE}
            />
            <Button
              className="screen-contest-ending__button"
              onClick={goToNextStep}
              label={isSurvey ? 'Je veux donner mon avis' : 'Commencer le défi'}
              isStyled
            />
          </div>
        </ActivitySplashScreen>
      ) : (
        <ScreenEnd />
      )}
    </div>
  );
};
export default ScreenContestEnding;
