import React, { MouseEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'component/button/Button';
import './ActivitySplashAction.scss';

interface IProps {
  isBackDisabled?: boolean;
  backButtonLabel?: string;
  onBackButton?: MouseEventHandler<HTMLButtonElement>;
  isContinueDisabled?: boolean;
  continueButtonLabel?: string;
  onContinueButton?: MouseEventHandler<HTMLButtonElement>;
}
const ActivitySplashAction = ({
  isBackDisabled = false,
  backButtonLabel = 'Revenir',
  continueButtonLabel = 'Continuer',
  isContinueDisabled = false,
  onBackButton = (): void => {},
  onContinueButton = (): void => {},
}: IProps): React.ReactElement => {
  return (
    <div className="activity-splash-action">
      <div className="activity-splash-action__container">
        <Button
          className="activity-splash-action__back-button"
          isDisabled={isBackDisabled}
          onClick={(event) => !isBackDisabled && onBackButton(event)}
        >
          <div className="activity-splash-action__back-button-icon-wrapper">
            <FontAwesomeIcon icon={['fas', 'arrow-left']} className="activity-splash-action__back-button-icon" />
          </div>
          {backButtonLabel}
        </Button>
        <Button
          isStyled
          className="activity-splash-action__next-button"
          isDisabled={isContinueDisabled}
          onClick={(event) => !isContinueDisabled && onContinueButton(event)}
          label={continueButtonLabel}
        />
      </div>
    </div>
  );
};
export default ActivitySplashAction;
