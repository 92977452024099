/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import content from './content.json';
import { EConsumption } from 'enum/user';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import ActivityCocktail from 'component/activity-cocktail/ActivityCocktail';
import CocktailResult from 'component/cocktail-result/CocktailResult';
import { CocktailSignSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import { IReduxState } from 'interface/i-redux-state';
import './ScreenCocktail.scss';
import cocktailInteraction from 'services/cocktail-interaction';

type Content = {
  description: string;
};

/**
 * The main screen to display for the cocktail list activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

interface IProps extends IReduxState {}

const ScreenCocktail = (props: IProps): React.ReactElement => {
  const { selectedIngredient } = props;
  const [isSplashScreen, setHasSplashScreen] = useState<boolean>(true);
  const [hasAllIngredient, setHasAllIngredient] = useState<boolean>(false);
  const [isOnResultPage, setOnResultPage] = useState<boolean>(false);
  const [isResultDisplayed, setResultIsDisplayed] = useState<boolean>(false);
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const { description }: Content = userConsumptionType === EConsumption.VAPER ? content.vaper : content.smoker;

  useEffect(() => {
    if (selectedIngredient[0] && selectedIngredient[1] && selectedIngredient[2]) {
      setHasAllIngredient(true);
    }
  }, [JSON.stringify(selectedIngredient)]);

  useEffect(() => {
    if (isOnResultPage) {
      window?.setTimeout((): void => {
        setResultIsDisplayed(true);
      }, 4500);
    }
  }, [isOnResultPage]);

  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen && isOnResultPage) {
      sceneInteraction.goToBarScreen(true);
      cocktailInteraction.setSelectedIngredient(null, 0);
      cocktailInteraction.setSelectedIngredient(null, 1);
      cocktailInteraction.setSelectedIngredient(null, 2);
    } else if (!isSplashScreen && hasAllIngredient && !isOnResultPage) {
      setOnResultPage(true);
    } else {
      setHasSplashScreen(false);
    }
  };
  const goToPrevStep: () => void = (): void => {
    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      if (isOnResultPage) {
        setHasSplashScreen(false);
      } else {
        setHasSplashScreen(true);
      }

      setResultIsDisplayed(false);
      setHasAllIngredient(false);
      setOnResultPage(false);
      cocktailInteraction.setSelectedIngredient(null, 0);
      cocktailInteraction.setSelectedIngredient(null, 1);
      cocktailInteraction.setSelectedIngredient(null, 2);
    }
  };

  const screenToDisplay: React.ReactNode = isSplashScreen ? (
    <ActivitySplashScreen title="Nos cocktails" description={description} imageSrc={CocktailSignSVG} />
  ) : isOnResultPage ? (
    <CocktailResult ingredientList={selectedIngredient} />
  ) : (
    <ActivityCocktail selectedIngredient={selectedIngredient} />
  );
  return (
    <div className="screen-cocktail">
      {screenToDisplay}
      <ActivitySplashAction
        isContinueDisabled={(!isSplashScreen && !hasAllIngredient) || (!isResultDisplayed && isOnResultPage)}
        continueButtonLabel={isSplashScreen || isOnResultPage ? 'Continuer' : 'Voir mon cocktail'}
        backButtonLabel={isOnResultPage ? 'Recommencer' : 'Revenir'}
        onContinueButton={() => goToNextStep()}
        onBackButton={() => goToPrevStep()}
      />
    </div>
  );
};
export default ScreenCocktail;
