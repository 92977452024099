import React from 'react';
import { NativeSelect } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userInteraction from 'services/user-interaction';
import { BilletSVG } from 'services/exports-list';
import { EConsumption } from 'enum/user';
import './RegistrationCard.scss';

const RegistrationCard = (): React.ReactElement => {
  const consumptionSelectList: React.ReactElement = (
    <>
      <option value={EConsumption.NOT_CHOSEN}>Choisir</option>
      <option value={EConsumption.SMOKER}>Je fume</option>
      <option value={EConsumption.VAPER}>Je vapote</option>
      <option value={EConsumption.OTHERS}>Je ne fume/vapote pas</option>
    </>
  );

  return (
    <div className="registration-card">
      <img src={BilletSVG} aria-hidden="true" alt="" className="registration-card__card-image" />
      <div className="registration-card__form">
        <p className="registration-card__subtext">
          <FontAwesomeIcon icon={['fas', 'caret-down']} />
          <span>Profil de consommation</span>
          <FontAwesomeIcon icon={['fas', 'caret-down']} />
        </p>
        <NativeSelect
          className="registration-card__select"
          placeholder="Fumeur ou non fumeur?"
          defaultValue="Fumeur"
          onChange={(event: any): void => {
            userInteraction.setConsumption(event.target.value);
          }}
        >
          {consumptionSelectList}
        </NativeSelect>
      </div>
    </div>
  );
};
export default RegistrationCard;
