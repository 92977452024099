import React, { ReactElement, useState } from 'react';

import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import { ScreenVapeTablePersonnagesSVG, ScreenVapeVapoteuseSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import ActivityVapeAnimation from 'component/activity-vape-animation/ActivityVapeAnimation';
import './ScreenVapePuzzle.scss';

/**
 * The main screen to display for the vape puzzle activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenVapePuzzle = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState(true);
  const [isPuzzlePlace, setPuzzlePlace] = useState(false);
  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(false);
    }
  };
  const goToPrevStep: () => void = (): void => {
    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(true);
    }
  };

  const VapeContent = (): ReactElement => {
    if (isSplashScreen) {
      return (
        <ActivitySplashScreen
          title="Quelle maladresse !"
          description="Mégan et Ludo dégustent leurs cocktails Nico-Bar à une table et discutent tranquillement de la vie quand tout à coup, tu fonces dans leur table. BANG!"
          imageSrc={ScreenVapeTablePersonnagesSVG}
        />
      );
    }
    if (isPuzzlePlace) {
      return (
        <ActivitySplashScreen
          title="C'est pas la taille qui compte"
          description="Produits toxiques, plastiques, métaux lourds… Finalement, la boucane qui s'échappe d'une vape est loin d'être de la vapeur d'eau! Vapoter peut causer des dommages irréversibles aux poumons, aux reins, au foie, cœur et peut même engendrer des problèmes de fertilité.  Petite vapoteuse, grande dangereuse !"
          imageSrc={ScreenVapeVapoteuseSVG}
        />
      );
    }
    return <ActivityVapeAnimation onFinishPuzzleEvt={() => setPuzzlePlace(true)} />;
  };

  return (
    <div className="screen-vape-puzzle">
      <VapeContent />
      <ActivitySplashAction
        continueButtonLabel={isSplashScreen ? 'Assemble la vape' : 'Continuer'}
        isContinueDisabled={!isSplashScreen && !isPuzzlePlace}
        onContinueButton={() => goToNextStep()}
        onBackButton={() => goToPrevStep()}
      />
    </div>
  );
};
export default ScreenVapePuzzle;
