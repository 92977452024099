import React from 'react';
import SVGLoader from 'component/svg/SVG';
import {
  CoinSVG,
  ContestBackground1PNG,
  ContestBackground2PNG,
  ContestMainBackgroundJPG,
  EmojiMoneyPNG,
  LogoDefiSVG,
  TitledCoinSVG,
  SurveyLogoSplashSVG,
  SurveyMainBackgroundJPG,
} from 'services/exports-list';
import googleTagManager from 'services/google-tag-manager';
import Button from 'component/button/Button';
import { EButtonSize } from 'enum/button';
import { EConsumption } from 'enum/user';
import userInteraction from 'services/user-interaction';
import './ScreenEndTop.scss';

const ScreenEndTop = (): React.ReactElement => {
  const today = new Date().getTime();
  const validUntil = new Date('2024-03-07').getTime();
  const isSurvey: boolean = process.env.REACT_APP_ENV === 'survey' && validUntil > today;

  const backgroundMainImage = {
    backgroundImage: `url(${isSurvey ? SurveyMainBackgroundJPG : ContestMainBackgroundJPG})`,
  };
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const finalTextByConsumptionType: () => JSX.Element = () => {
    switch (userConsumptionType) {
      case EConsumption.SMOKER:
        return <>Consulte nos trucs et conseils pour cesser de fumer ou vapoter!</>;
      case EConsumption.VAPER:
        return (
          <>
            Arrête de vapoter <strong>1 semaine</strong> et tu pourrais{' '}
            <span>gagner jusqu&apos;à 2&nbsp;000&nbsp;$&nbsp;!</span>
          </>
        );
      default:
        return (
          <>
            Accompagne un ami qui veut arrêter de fumer <strong>1 semaine</strong> et tu pourrais{' '}
            <span>gagner jusqu&apos;à 2&nbsp;000&nbsp;$&nbsp;!</span>
          </>
        );
    }
  };

  return (
    <div className="screen-end-top" style={backgroundMainImage}>
      <img className="screen-end-top__splash-1" alt="" aria-hidden="true" src={ContestBackground1PNG} />
      <img className="screen-end-top__splash-2" alt="" aria-hidden="true" src={ContestBackground2PNG} />
      <div className="screen-end-top__content-wrapper">
        <div className="screen-end-top__header-content">
          <img src={EmojiMoneyPNG} aria-hidden="true" alt="" className="screen-end-top__header-emoji" />
          <SVGLoader src={isSurvey ? SurveyLogoSplashSVG : LogoDefiSVG} className="screen-end-top__header-title" />
          <SVGLoader src={TitledCoinSVG} className="screen-end-top__header-coin" />
        </div>
        <div className="screen-end-top__content">
          <h3 className="screen-end-top__content-title">{isSurvey ? 'On aimerait ton avis!' : 'Motivé à arrêter?'}</h3>
          {isSurvey ? (
            <p className="activity-splash-screen__content-description">
              Réponds à notre sondage sur le Nico-Bar virtuel et tu pourrais gagner l’un des 3 prix de &nbsp;
              <span>1500 en argent!</span>
            </p>
          ) : (
            finalTextByConsumptionType()
          )}
          <p className="screen-end-top__content-description" />
          <Button
            link={isSurvey ? process.env.REACT_APP_NICOBAR_SURVEY_URL : process.env.REACT_APP_NICOBAR_MANY_CHAT_URL}
            size={EButtonSize.MEDIUM}
            label={isSurvey ? 'Je veux donner mon avis' : 'En savoir plus'}
            isStyled
            onClick={() => googleTagManager.onClickOnChallenge('contest')}
            icon={['fas', 'sign-out']}
          />
          <SVGLoader src={CoinSVG} className="screen-end-top__content-coin" />
        </div>
      </div>
    </div>
  );
};
export default ScreenEndTop;
