import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import './QuizzQuestion.scss';

interface IProps {
  image: string;
  title: string;
  answer: Array<string>;
  onSubmitAnswer: (index: number) => void;
}
const QuizzQuestion = ({ image, title, answer, onSubmitAnswer }: IProps): React.ReactElement => {
  const [value, setValue] = React.useState<number>(NaN);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formValue: number = parseInt((event.target as HTMLInputElement).value, 10) || 0;
    setValue(formValue);
    onSubmitAnswer(formValue);
  };

  return (
    <div className="quizz-question">
      <div className="quizz-question__image">
        <div className="image--ratio image--ratio--16-9">
          <img src={`${process.env.PUBLIC_URL}/assets/${image}`} alt="" />
        </div>
      </div>
      <h2 className="quizz-question__title">{title}</h2>
      <form className="quizz-question__form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
            {answer.map((singleAnswer: string, index: number) => {
              return <FormControlLabel value={index} control={<Radio />} label={singleAnswer} />;
            })}
          </RadioGroup>
        </FormControl>
      </form>
    </div>
  );
};
export default QuizzQuestion;
