import React, { useEffect, useState } from 'react';
import SVGLoader from 'component/svg/SVG';
import { IReduxState } from 'interface/i-redux-state';
import {
  HorizontalScrollSVG,
  InterieurBarSVG,
  StationCasseTeteSVG,
  StationCocktailSVG,
  StationDefiSVG,
  StationFrigoSVG,
  StationMiroirSVG,
  StationQuizSVG,
  StationSanteMentaleSVG,
  SurveyPosterSVG,
  SurveyWinSVG,
} from 'services/exports-list';
import ActivityButton from 'component/activity-button/ActivityButton';
import { EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';
import sceneInteraction from 'services/scene-interaction';
import utils from 'services/utils';
import './ScreenBar.scss';

interface IProps extends IReduxState {}

const today = new Date().getTime();
const validUntil = new Date('2024-03-07').getTime();
const isSurvey: boolean = process.env.REACT_APP_ENV === 'survey' && validUntil > today;
const ScreenBar = (props: IProps): React.ReactElement => {
  const [hasSwipeAnimation, setSwipeAnimation] = useState<boolean>(false);
  const { currentBarScene, consumption } = props;

  useEffect(() => {
    const screenBarElement: HTMLElement | null = window?.document?.querySelector('.screen-bar');

    if (currentBarScene === EScene.INSIDE_BAR) {
      sceneInteraction.goToNextBarScreen();
    }
    /**
     * Add a scroll to on mobile to go to the middle at the begenning.
     */
    if (currentBarScene === EScene.COCKTAIL) {
      const childWidth: number = screenBarElement?.querySelector('.screen-bar__bar-wrapper')?.clientWidth || 0;
      window?.setTimeout(() => {
        utils.scrollTo({
          element: screenBarElement,
          duration: 1.5,
          offsetY: 0,
          offsetX: childWidth,
          delay: 0,
          ease: 'linear',
        });
        window?.setTimeout(() => {
          utils.scrollTo({
            element: screenBarElement,
            duration: 1.5,
            offsetY: 0,
            offsetX: 0,
            delay: 0,
            ease: 'linear',
          });
          window?.setTimeout(() => {
            setSwipeAnimation(true);
            screenBarElement?.addEventListener('scroll', (event: Event) => {
              const targetEvent: HTMLElement = event?.target as HTMLElement;
              if (targetEvent && targetEvent.scrollLeft > 250) {
                setSwipeAnimation(false);
              }
            });
          }, 2000);
        }, 2000);
      }, 1500);
    }

    if (currentBarScene !== EScene.COCKTAIL && currentBarScene !== EScene.INSIDE_BAR) {
      setSwipeAnimation(true);
      screenBarElement?.addEventListener('scroll', (event: Event) => {
        const targetEvent: HTMLElement = event?.target as HTMLElement;
        if (targetEvent && targetEvent.scrollLeft > 250) {
          setSwipeAnimation(false);
        }
      });
    }
  }, [currentBarScene]);

  return (
    <div className="screen-bar">
      <div className="screen-bar__bar-wrapper">
        {hasSwipeAnimation && <SVGLoader src={HorizontalScrollSVG} className="screen-bar__scroll-anim" />}
        <div className="screen-bar__bar-wrapper-overflow">
          <ActivityButton
            isActive={currentBarScene === EScene.QUIZZ}
            extraClassName="screen-bar__quiz"
            SVGImage={StationQuizSVG}
            onClickEvt={() => sceneInteraction.goToScreen(EScene.QUIZZ)}
            label="Essayer notre quiz"
          />
          <ActivityButton
            isActive={currentBarScene === EScene.TOYS_MACHINE}
            extraClassName="screen-bar__mental"
            SVGImage={StationSanteMentaleSVG}
            onClickEvt={() => sceneInteraction.goToScreen(EScene.TOYS_MACHINE)}
            label="Attrape un toutou"
          />
          <ActivityButton
            isActive={currentBarScene === EScene.MIRROR}
            extraClassName="screen-bar__mirror"
            SVGImage={StationMiroirSVG}
            onClickEvt={() => sceneInteraction.goToScreen(EScene.MIRROR)}
            label="Aller aux toilettes"
          />
          <ActivityButton
            isActive={currentBarScene === EScene.END}
            extraClassName="screen-bar__defi"
            SVGImage={isSurvey ? SurveyPosterSVG : StationDefiSVG}
            onClickEvt={() => {
              sceneInteraction.goToScreen(EScene.END);
            }}
            label="Visiter le défi"
          />
          <ActivityButton
            isActive={currentBarScene === EScene.COCKTAIL}
            extraClassName="screen-bar__cocktail"
            SVGImage={StationCocktailSVG}
            onClickEvt={() => sceneInteraction.goToScreen(EScene.COCKTAIL)}
            label="Voir les cocktails"
          />
          <ActivityButton
            isActive={currentBarScene === EScene.FREEZER}
            extraClassName="screen-bar__freezer"
            SVGImage={StationFrigoSVG}
            onClickEvt={() => sceneInteraction.goToScreen(EScene.FREEZER)}
            label="Débarrer le frigo"
          />
          <ActivityButton
            isActive={currentBarScene === EScene.VAPE_PUZZLE}
            extraClassName={`screen-bar__puzzle ${consumption !== EConsumption.VAPER && 'disabled'}`}
            SVGImage={StationCasseTeteSVG}
            onClickEvt={() => consumption === EConsumption.VAPER && sceneInteraction.goToScreen(EScene.VAPE_PUZZLE)}
            label="Assemble la vape"
          />
          {isSurvey && (
            <a
              target="_blank"
              className="activity-button screen-bar__win"
              title="150$ à gagner"
              href={process.env.REACT_APP_NICOBAR_SURVEY_URL}
              rel="noreferrer"
            >
              <SVGLoader src={SurveyWinSVG} className="activity-button__image" />
            </a>
          )}
          <SVGLoader className="screen-bar__bar" src={InterieurBarSVG} />
        </div>
      </div>
    </div>
  );
};
export default ScreenBar;
