import React, { useState } from 'react';
import { NeonQuizzSVG } from 'services/exports-list';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import sceneInteraction from 'services/scene-interaction';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import ActivityQuizz from 'component/activity-quizz/ActivityQuizz';
import './ScreenQuizz.scss';

/**
 * The main screen to display for the quizz activity
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenQuizz = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState<boolean>(true);
  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(false);
    }
  };

  const goToPrevStep: () => void = (): void => {
    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(true);
    }
  };

  return (
    <div className="screen-quizz">
      {isSplashScreen ? (
        <>
          <ActivitySplashScreen
            title="Quel cocktail Nico-Bar es-tu?"
            description="Ouf, ça fait beaucoup d'infos à traiter tout d'un coup? <br/><br/>On t'a préparé un p'tit quiz léger pour finir ta visite au Nico-Bar tout en beauté!<br/><br/>N'hésite pas à partager ton résultat sur les réseaux sociaux!"
            imageSrc={NeonQuizzSVG}
          />
          <ActivitySplashAction
            continueButtonLabel="Commencer le quiz"
            onBackButton={() => goToPrevStep()}
            onContinueButton={() => goToNextStep()}
          />
        </>
      ) : (
        <ActivityQuizz />
      )}
    </div>
  );
};
export default ScreenQuizz;
