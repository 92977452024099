import React, { useState, useEffect } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import _ from 'lodash';
import { BoingMP3 } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import QuizzSharing from 'component/quizz-sharing/QuizzSharing';
import './QuizzResults.scss';

interface IProps {
  answerState: {
    [key: number]: number;
  };
  results: Array<{
    title: string;
    description: string;
    image: string;
  }>;
}
const QuizzResults = ({ answerState, results }: IProps): React.ReactElement => {
  const [mostPopularAnswerIndex, setAnswerIndex] = useState<number>(0);
  const isMusicPlaying: boolean = sceneInteraction.isMusicEnabled();

  useEffect(() => {
    const answerList: Array<number> = Object.values(answerState);
    // Remove the value "3" from the quizz result, since it only have 3 cocktail and not 4 possible.
    const newArray: Array<number> = answerList.filter((item: number) => item !== 3);
    const mostPopular: string | number | undefined = _.head(_(newArray).countBy().entries().maxBy(_.last));
    setAnswerIndex((mostPopular as number) || 0);
  }, [answerState]);

  return (
    <div className="quizz-results">
      {isMusicPlaying && <H5AudioPlayer src={BoingMP3} autoPlay />}
      <img
        alt=""
        aria-hidden="true"
        src={`${process.env.PUBLIC_URL}/assets/${results[mostPopularAnswerIndex].image}`}
        className="quizz-results__images"
      />
      <h2 className="quizz-results__title">{results[mostPopularAnswerIndex].title}</h2>
      <p className="quizz-results__description">{results[mostPopularAnswerIndex].description}</p>
      <QuizzSharing resultsIndex={mostPopularAnswerIndex} />
    </div>
  );
};
export default QuizzResults;
