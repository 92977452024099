import React from 'react';
import Button from 'component/button/Button';
import './MirrorSplashScreen.scss';

interface IProps {
  onClick: () => void;
}

const MirrorSplashScreen = ({ onClick }: IProps): React.ReactElement => {
  return (
    <div className="mirror-splash-screen">
      <p className="mirror-splash-screen__title">
        Miroir <span className="mirror-splash-screen__title-mirror">Miroir</span>
      </p>
      <div className="mirror-splash-screen__content">
        <Button label="Démarrer l'expérience" isStyled onClick={onClick} />
      </div>
    </div>
  );
};
export default MirrorSplashScreen;
