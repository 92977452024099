import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { EConsumption } from 'enum/user';
import SVGLoader from 'component/svg/SVG';
import MirrorSplashScreen from 'component/mirror-splash-screen/MirrorSplashScreen';
import MirrorModalSkin from 'component/mirror-modal-skin/MirrorModalSkin';
import DeepARMirror from 'component/deepar-mirror/DeepARMirror';
import { MiroirFondSVG, MiroirSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import Button from 'component/button/Button';

import './ActivityMirror.scss';

interface IProps {
  isAfterMirror?: boolean;
}

const ActivityMirror = ({ isAfterMirror = false }: IProps): React.ReactElement => {
  const [currentIndex, setIndex] = useState<number>(0);
  const backgroundStyle = {
    backgroundImage: `url(${MiroirFondSVG})`,
  };
  const [currentSkinFilter, setSkinFilter] = useState<number>(0);
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();

  return (
    <div className="activity-mirror" style={backgroundStyle}>
      <div className="activity-mirror__mirror">
        <SVGLoader src={MiroirSVG} className="activity-mirror__mirror-img" />
        <div className="activity-mirror__mirror-content">
          <Modal
            closeAfterTransition
            disableEscapeKeyDown={false}
            onBackdropClick={(): boolean => {
              if (!isAfterMirror) {
                return false;
              }
              sceneInteraction.goToBarScreen(true);
              return false;
            }}
            open={
              (currentIndex === 1 && userConsumptionType !== EConsumption.VAPER) || (isAfterMirror && currentIndex >= 2)
            }
          >
            <SwitchTransition>
              <CSSTransition
                key={currentIndex}
                addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                classNames="fade"
              >
                <div className="activity-mirror__modal modal">
                  {currentIndex === 1 && (
                    <MirrorModalSkin
                      onClick={(skinIndex) => {
                        setIndex(currentIndex + 1);
                        setSkinFilter(skinIndex);
                      }}
                    />
                  )}
                  {isAfterMirror && currentIndex >= 2 && (
                    <>
                      <Button
                        className="modal-close"
                        aria-label="Fermer la fenêtre"
                        icon={['fas', 'times']}
                        onClick={() => sceneInteraction.goToBarScreen(true)}
                      />
                      {userConsumptionType === EConsumption.VAPER ? (
                        <>
                          <h3 className="mt-4">Surprise! Sans le savoir, tu es un rat de laboratoire!</h3>
                          <p className="mt-4">
                            On ne peut pas encore te dire les impacts du vapotage à long terme sur ton corps, parce
                            qu&apos;il y a encore peu de résultats d&apos;études sur le sujet. On sait toutefois que les
                            produits utilisés dans une vapoteuse peuvent être toxiques au point de modifier ton ADN…Peu
                            rassurant!
                          </p>
                        </>
                      ) : (
                        <>
                          <ul>
                            <li>Les yeux cernés</li>
                            <li>Le teint terne</li>
                            <li>Les dents jaunes</li>
                            <li>Les cheveux secs</li>
                          </ul>
                          <p className="mt-4">Y&apos;a matière à réflexion!</p>
                        </>
                      )}
                      <p className="mt-4">
                        Pour du soutien immédiat, communiquez avec la ligne J&apos;ARRÊTE AU{' '}
                        <a className="activity-mirror__phone-number" href="tel:+18665277383">
                          1&nbsp;866&nbsp;527-7383
                        </a>
                      </p>
                    </>
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </Modal>
          <SwitchTransition>
            <CSSTransition
              key={currentIndex}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames="fade"
            >
              <>
                {currentIndex === 0 && (
                  <MirrorSplashScreen
                    onClick={() =>
                      setIndex(userConsumptionType === EConsumption.VAPER ? currentIndex + 2 : currentIndex + 1)
                    }
                  />
                )}
                {currentIndex >= 2 && <DeepARMirror isUnmount={isAfterMirror} selectedSkinIndex={currentSkinFilter} />}
              </>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};
export default ActivityMirror;
