import React, { useEffect, useState } from 'react';
import Button from 'component/button/Button';
import googleTagManager from 'services/google-tag-manager';

import './QuizzSharing.scss';
import { EConsumption } from 'enum/user';
import userInteraction from 'services/user-interaction';

declare global {
  interface Window {
    fbAsyncInit: any;
    FB: any;
  }
}

interface IProps {
  resultsIndex?: number;
}
const QuizzSharing = ({ resultsIndex = 1 }: IProps): React.ReactElement => {
  const consumptionType: EConsumption = userInteraction.getUserConsumption();
  const [cocktailIndex, setCocktailIndex] = useState<number>(resultsIndex);
  useEffect(() => {
    const cocktailByType: number = Number(
      consumptionType === EConsumption.VAPER ? parseInt(`${resultsIndex}`, 10) + 3 : resultsIndex
    );
    setCocktailIndex(cocktailByType);
  }, [resultsIndex]);
  const shareOnFB = (): void => {
    window?.FB.ui({
      method: 'share',
      href: `https://bar-virtuel.lenicobar.com/results/cocktail-${cocktailIndex}.html`,
    });
    googleTagManager.onShareOnFB();
  };
  return (
    <div className="quizz-sharing">
      <div id="fb-root" />
      <Button isStyled label="Partager le résultat" onClick={shareOnFB} />
    </div>
  );
};
export default QuizzSharing;
