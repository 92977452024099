import googleTagManager from './google-tag-manager';
import sceneInteraction from 'services/scene-interaction';
import userInteraction from 'services/user-interaction';
import {
  BarCocktailCoulisCeramiqueSVG,
  BarCocktailFormalehydeSVG,
  BarCocktailIngredientBatterieSVG,
  BarCocktailIngredientBoulesMitesSVG,
  BarCocktailIngredientDetergentToiletteSVG,
  BarCocktailIngredientDissolvantOnglesSVG,
  BarCocktailIngredientGoudronSVG,
  BarCocktailIngredientLaveGlaceSVG,
  BarCocktailIngredientMercureSVG,
  BarCocktailIngredientPesticideSVG,
  BarCocktailIngredientTolueneSVG,
  BarCocktailNickelSVG,
  BarCocktailPlombSVG,
  CocktailLaVeuveNoireSVG,
  CocktailLeRainbowShotSVG,
  CocktailLeSmokeyEyeSVG,
  CocktailMenthEurSVG,
  CocktailNameLaVeuveNoireSVG,
  CocktailNameLeRainbowShotSVG,
  CocktailNameLeSmokeyEyeSVG,
  CocktailNameMenthEurSVG,
  CocktailNameNicotineOnTheRockSVG,
  CocktailNameZombieSVG,
  CocktailNicotineOnTheRockSVG,
  CocktailZombieSVG,
} from 'services/exports-list';
import { ECocktaiIngredientlList } from 'enum/cocktail';
import { selectIngredient } from 'action/cocktail-action';
import IngredientJSON from 'component/activity-cocktail/ingredient.json';
import { ICocktailSelection } from 'interface/i-cocktail-selection';
import { EConsumption } from 'enum/user';

class CocktailInteraction {
  constructor() {
    this.setSelectedIngredient = this.setSelectedIngredient.bind(this);
    this.getCocktailByName = this.getCocktailByName.bind(this);
  }

  /**
   * getCocktailByName
   * Get the image of the cocktail by is enum name
   * @params name: ECocktaiIngredientlList - The enum name of the cocktail
   * @return: string - the SVG export of the image
   */
  getCocktailByName(name: ECocktaiIngredientlList): string {
    switch (name) {
      case ECocktaiIngredientlList.COULIS_CERAMIQUE:
        return BarCocktailCoulisCeramiqueSVG;
      case ECocktaiIngredientlList.FORMALEHYDE:
        return BarCocktailFormalehydeSVG;
      case ECocktaiIngredientlList.INGREDIENT_BATTERIE:
        return BarCocktailIngredientBatterieSVG;
      case ECocktaiIngredientlList.INGREDIENT_BOULESMITES:
        return BarCocktailIngredientBoulesMitesSVG;
      case ECocktaiIngredientlList.INGREDIENT_DETERGENT_TOILETTE:
        return BarCocktailIngredientDetergentToiletteSVG;
      case ECocktaiIngredientlList.INGREDIENT_DISSOLVANT_ONGLES:
        return BarCocktailIngredientDissolvantOnglesSVG;
      case ECocktaiIngredientlList.INGREDIENT_GOUDRON:
        return BarCocktailIngredientGoudronSVG;
      case ECocktaiIngredientlList.INGREDIENT_LAVE_GLACE:
        return BarCocktailIngredientLaveGlaceSVG;
      case ECocktaiIngredientlList.INGREDIENT_MERCURE:
        return BarCocktailIngredientMercureSVG;
      case ECocktaiIngredientlList.INGREDIENT_PESTICIDE:
        return BarCocktailIngredientPesticideSVG;
      case ECocktaiIngredientlList.NICKEL:
        return BarCocktailNickelSVG;
      case ECocktaiIngredientlList.PLOMB:
        return BarCocktailPlombSVG;
      case ECocktaiIngredientlList.INGREDIENT_TOLUENE:
        return BarCocktailIngredientTolueneSVG;
      default:
        return BarCocktailCoulisCeramiqueSVG;
    }
  }

  /**
   * Get a cocktail base on the first ingredient you choose.
   * @param ingredient : ECocktaiIngredientlList- The first ingredient of the list
   */
  getCocktailByIngredient(ingredient: ECocktaiIngredientlList): ICocktailSelection {
    googleTagManager.onFinishCocktail();
    const consumptionType: EConsumption = userInteraction.getUserConsumption();
    if (consumptionType !== EConsumption.VAPER) {
      switch (ingredient) {
        case ECocktaiIngredientlList.INGREDIENT_BATTERIE:
        case ECocktaiIngredientlList.INGREDIENT_PESTICIDE:
          return {
            title: CocktailNameLeSmokeyEyeSVG,
            image: CocktailLeSmokeyEyeSVG,
            description:
              'Ce shooter hautement toxique contient des ingrédients carcinogènes améliorant la diffusion de la nicotine dans le cerveau. Un must!',
          };

        case ECocktaiIngredientlList.INGREDIENT_TOLUENE:
        case ECocktaiIngredientlList.INGREDIENT_MERCURE:
        case ECocktaiIngredientlList.INGREDIENT_GOUDRON:
          return {
            title: CocktailNameLaVeuveNoireSVG,
            image: CocktailLaVeuveNoireSVG,
            description:
              'Limitez votre consommation, car son absorption peut obstruer le système circulatoire et respiratoire. Oups!',
          };
        default:
          return {
            title: CocktailNameNicotineOnTheRockSVG,
            image: CocktailNicotineOnTheRockSVG,
            description:
              "Ses ingrédients améliorent le goût, adoucissent le produit et facilitent l'absorption de la nicotine, ce qui renforce la dépendance de notre clientèle.",
          };
      }
    }

    switch (ingredient) {
      case ECocktaiIngredientlList.INGREDIENT_LAVE_GLACE:
      case ECocktaiIngredientlList.INGREDIENT_PESTICIDE:
        return {
          title: CocktailNameLeRainbowShotSVG,
          image: CocktailLeRainbowShotSVG,
          description:
            'Une multitude de saveurs offertes! À vos risques… On connaît peu les effets à long terme. Fidélité garantie, car on ajoute de la nicotine.',
        };

      case ECocktaiIngredientlList.FORMALEHYDE:
      case ECocktaiIngredientlList.NICKEL:
        return {
          title: CocktailNameZombieSVG,
          image: CocktailZombieSVG,
          description:
            'Son goût tropical et fruité vous fera oublier ses ingrédients toxiques que le consommer peut provoquer une modification de ton ADN.',
        };
      default:
        return {
          title: CocktailNameMenthEurSVG,
          image: CocktailMenthEurSVG,
          description:
            "Un cocktail envoûtant qui finira par te procurer du stress, de l'anxiété et des troubles du sommeil. Oups!",
        };
    }
  }

  getIngredientListByConsumption(consumptionType: EConsumption): Array<ECocktaiIngredientlList> {
    if (consumptionType === EConsumption.VAPER) {
      return [
        ECocktaiIngredientlList.PLOMB,
        ECocktaiIngredientlList.INGREDIENT_LAVE_GLACE,
        ECocktaiIngredientlList.FORMALEHYDE,
        ECocktaiIngredientlList.NICKEL,
        ECocktaiIngredientlList.COULIS_CERAMIQUE,
        ECocktaiIngredientlList.INGREDIENT_PESTICIDE,
      ];
    }
    return [
      ECocktaiIngredientlList.INGREDIENT_BATTERIE,
      ECocktaiIngredientlList.INGREDIENT_MERCURE,
      ECocktaiIngredientlList.INGREDIENT_GOUDRON,
      ECocktaiIngredientlList.INGREDIENT_DETERGENT_TOILETTE,
      ECocktaiIngredientlList.INGREDIENT_BOULESMITES,
      ECocktaiIngredientlList.INGREDIENT_DISSOLVANT_ONGLES,
      ECocktaiIngredientlList.INGREDIENT_TOLUENE,
      ECocktaiIngredientlList.INGREDIENT_PESTICIDE,
    ];
  }

  /**
   * Get a text to describe where to find this ingredient.
   * @param ingredient : ECocktaiIngredientlList- The first ingredient of the list
   * @return string - The description of the ingredient
   */
  getIngredientWhereToFind(ingredient: ECocktaiIngredientlList | null): string {
    if (ingredient && IngredientJSON.whereToFind[ingredient]) {
      return IngredientJSON.whereToFind[ingredient];
    }
    return 'Glisse et dépose sur les chiffres les 3 ingrédients exclusifs parmi la sélection du Nico-Bar pour que Nico prépare ton cocktail.';
  }

  /**
   * Get a warning about the ingredient.
   * @param ingredient : ECocktaiIngredientlList- The first ingredient of the list
   * @return string - The description of the ingredient
   */
  getIngredientWarning(ingredient: ECocktaiIngredientlList | null): string {
    if (ingredient && IngredientJSON.warning[ingredient]) {
      return IngredientJSON.warning[ingredient];
    }
    return '';
  }

  setSelectedIngredient(ingredient: ECocktaiIngredientlList | null, index: number): void {
    sceneInteraction.storeDispatch(selectIngredient(ingredient, index));
  }
}

export default new CocktailInteraction();
