import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Button from 'component/button/Button';
import './MirrorModalSkin.scss';

interface IProps {
  onClick: (skinIndex: number) => void;
}

const MirrorModalSkin = ({ onClick }: IProps): React.ReactElement => {
  const [currentSkinFilter, setSkinFilter] = useState<number>(0);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formValue: number = parseInt((event.target as HTMLInputElement).value, 10) || 0;
    setSkinFilter(formValue);
  };

  const filterPossibilities: Array<string> = ['très pale', 'pale', 'neutre', 'peu foncer'];

  return (
    <div className="mirror-modal-skin">
      <p className="mirror-modal-skin__content">Choisis la nuance qui représente le mieux ta couleur de peau&nbsp;:</p>
      <form className="mirror-modal-skin__form">
        <FormControl component="fieldset">
          <RadioGroup aria-label="quiz" name="quiz" value={currentSkinFilter} onChange={handleRadioChange}>
            {filterPossibilities.map((singleAnswer: string, index: number) => {
              return <FormControlLabel value={index} control={<Radio />} label={singleAnswer} />;
            })}
          </RadioGroup>
        </FormControl>
      </form>
      <Button
        isStyled
        className="mirror-modal-skin__button"
        label="Suivant"
        onClick={() => onClick(currentSkinFilter)}
      />
    </div>
  );
};

export default MirrorModalSkin;
