import React from 'react';
import content from './content.json';
import SVGLoader from 'component/svg/SVG';
import {
  MachineToutousOmbreSVG,
  MachineToutousSVG,
  ToutouAnxieteSVG,
  ToutouStressSVG,
  ToutouTroublesCognitifsSVG,
  ToutouTroublesSommeilSVG,
} from 'services/exports-list';
import ToysMachineButton from 'component/toys-machine-button/ToysMachineButton';
import ToysMachineWindow from 'component/toys-machine-window/ToysMachineWindow';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import { EConsumption } from 'enum/user';
import { EToys } from 'enum/toys';
import toysInteraction from 'services/toys-interaction';
import userInteraction from 'services/user-interaction';
import './ActivityToysMachine.scss';

interface IProps {
  onResultEvt?: () => void;
}

const ActivityToysMachine = ({ onResultEvt = () => {} }: IProps): React.ReactElement => {
  const stepNumber: number = toysInteraction.getToysInfo().stepNumberToys;
  const finalStepNumber: number = toysInteraction.getToysInfo().finalStepNumberToys;
  const { isDropped } = toysInteraction.getToysInfo();
  const { isCraneDown } = toysInteraction.getToysInfo();

  const onCraneDrop = (): void => {
    toysInteraction.setDropState(true);
    toysInteraction.setCraneIsDown(true);
    const selectedStep = stepNumber;
    window?.setTimeout((): void => {
      toysInteraction.setCraneIsDown(false);
      window?.setTimeout((): void => {
        toysInteraction.setStepNumberToys(5);
        window?.setTimeout((): void => {
          toysInteraction.setFinalStepNumberToys(selectedStep);
          onResultEvt();
        }, 1250);
      }, 750);
    }, 750);
  };

  if (finalStepNumber) {
    const selectedToys: EToys | null = Object.entries(EToys)[finalStepNumber - 1][1];

    if (selectedToys) {
      type ObjectKey = keyof typeof responses;
      type Response = {
        type: keyof typeof imageToys;
        title: string;
        description: string;
      };

      const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
      const responses: object = userConsumptionType === EConsumption.VAPER ? content.vaper : content.smoker;
      const toy = selectedToys?.toLowerCase() as ObjectKey;
      const { type, title, description }: Response = responses[toy];
      const imageToys: object = {
        Anxiete: ToutouAnxieteSVG,
        TroublesCognitifs: ToutouTroublesCognitifsSVG,
        Stress: ToutouStressSVG,
        TroublesSommeil: ToutouTroublesSommeilSVG,
      };

      return <ActivitySplashScreen imageSrc={imageToys[type]} title={title} description={description} />;
    }
  }

  return (
    <div className="activity-toys-machine">
      <div className="activity-toys-machine__container">
        <div className="activity-toys-machine__machine-wrapper">
          <SVGLoader src={MachineToutousSVG} className="activity-toys-machine__machine" />
          <SVGLoader src={MachineToutousOmbreSVG} className="activity-toys-machine__machine-shadow" />
          <div className="activity-toys-machine__window">
            <ToysMachineWindow stepNumber={stepNumber} isCraneDown={isCraneDown} isDropped={isDropped} />
          </div>
          <div className="activity-toys-machine__buttons">
            <ToysMachineButton
              onChange={(number) => toysInteraction.setStepNumberToys(number)}
              onDrop={() => onCraneDrop()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ActivityToysMachine;
