import React, { useEffect, useState } from 'react';
import Button from 'component/button/Button';
import RegistrationCard from 'component/registration-card/RegistrationCard';
import { IReduxState } from 'interface/i-redux-state';
import { DoorManSVG, WallTextureSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import './ScreenRegister.scss';

interface IProps extends IReduxState {}

const ScreenRegister = (props: IProps): React.ReactElement => {
  const { consumption } = props;
  const backgroundStyle = {
    backgroundImage: `url(${WallTextureSVG})`,
  };
  const [isContinueDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsDisabled(!consumption);
  }, [consumption]);

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  return (
    <div className="screen-register" style={backgroundStyle}>
      <div className="screen-register__register-section">
        <div className="screen-register__register-text">
          <h1 className="screen-register__register-title">Une ambiance toxique</h1>
          <p className="screen-register__register-content">
            Découvre nos cocktails avec leurs ingrédients toxiques, leurs effets sur ton corps et l&apos;impact sur ta
            santé mentale. Explore les différentes stations pour en savoir plus.
          </p>
          <p className="screen-register__register-content">
            <span>Active le son</span> en cliquant sur l&apos;icône en haut à droite.
          </p>
        </div>
        <RegistrationCard />
        <Button
          isStyled
          label="Entrer"
          onClick={!isContinueDisabled ? () => sceneInteraction.goToNextScreen() : () => {}}
          isDisabled={isContinueDisabled}
        />
      </div>
      <img src={DoorManSVG} alt="Doorman" aria-hidden="true" className="screen-register__doorman" />
    </div>
  );
};
export default ScreenRegister;
