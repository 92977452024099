import React from 'react';
import SVGLoader from 'component/svg/SVG';
import './ActivityButton.scss';
import Button from 'component/button/Button';

interface IProps {
  isActive?: boolean;
  SVGImage?: string;
  extraClassName?: string;
  label?: string;
  onClickEvt?: () => void;
}

const ActivityButton = ({
  isActive = false,
  SVGImage = '',
  extraClassName = '',
  label = '',
  onClickEvt = () => {},
}: IProps): React.ReactElement => {
  return (
    <button
      type="button"
      className={`activity-button ${extraClassName} ${isActive && '--is-active'}`}
      onClick={() => onClickEvt()}
    >
      <SVGLoader className="activity-button__image" src={SVGImage} />
      {isActive && <Button isStyled className="activity-button__btn" label={label} />}
    </button>
  );
};
export default ActivityButton;
