import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
import H5AudioPlayer from 'react-h5-audio-player';
import {
  ClickVapoteuseMP3,
  VapoteuseBatterieSVG,
  VapoteuseBlankSVG,
  VapoteuseDripTipSVG,
  VapoteuseLiquidPODSVG,
  VapoteusePowerIndicatorSVG,
} from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import vapeInteraction from 'services/vape-interaction';
import SVGLoader from 'component/svg/SVG';
import { EVapePiece } from 'enum/puzzle';
import VapePieceInformation from 'component/vape-piece-information/VapePieceInformation';
import './ActivityVapePuzzle.scss';

interface IProps {
  onFinishPuzzleEvt?: () => void;
}

const ActivityVapePuzzle = ({ onFinishPuzzleEvt = () => {} }: IProps): React.ReactElement => {
  const vapeWrapper = useRef<HTMLDivElement>(null);
  const x: number = vapeWrapper?.current?.offsetLeft || 0;
  const y: number = vapeWrapper?.current?.offsetTop || 0;
  const isMusicEnabled = sceneInteraction.isMusicEnabled();

  const [dripPos, setDripPos] = useState<number[]>([130, 200]);
  const [dripIsDrop, setDripDropped] = useState<boolean>(false);
  const [podPos, setPodPos] = useState<number[]>([140, 30]);
  const [podIsDrop, setPodDropped] = useState<boolean>(false);
  const [batteryPos, setBatteryPos] = useState<number[]>([40, 20]);
  const [batteryIsDrop, setBatteryDropped] = useState<boolean>(false);
  const [powerPos, setPowerPos] = useState<number[]>([130, 140]);
  const [powerIsDrop, setPowerDropped] = useState<boolean>(false);

  const [{ isDraggingDrip }, dripTipPieceRef] = useDrag(() => ({
    type: EVapePiece.DRIP_TIP,
    item: { id: EVapePiece.DRIP_TIP },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDraggingDrip: !!monitor.isDragging(),
    }),
  }));

  const [{ isDraggingPod }, podPieceRef] = useDrag(() => ({
    type: EVapePiece.LIQUID_POD,
    item: { id: EVapePiece.LIQUID_POD },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDraggingPod: !!monitor.isDragging(),
    }),
  }));

  const [{ isDraggingBattery }, batteryPieceRef] = useDrag(() => ({
    type: EVapePiece.BATTERY,
    item: { id: EVapePiece.BATTERY },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDraggingBattery: !!monitor.isDragging(),
    }),
  }));
  const [{ isDraggingPower }, powerPieceRef] = useDrag(() => ({
    type: EVapePiece.POWER_INDICATOR,
    item: { id: EVapePiece.POWER_INDICATOR },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDraggingPower: !!monitor.isDragging(),
    }),
  }));

  const [, dripTipRef] = useDrop(
    () => ({
      accept: EVapePiece.DRIP_TIP,
      drop: () => {
        setDripPos([x + 3, y + 1]);
        setDripDropped(true);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [x, y]
  );

  const [, liquidPodRef] = useDrop(
    () => ({
      accept: EVapePiece.LIQUID_POD,
      drop: () => {
        setPodPos([x + 2.5, y + 22]);
        setPodDropped(true);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [x, y]
  );
  const [, batteryRef] = useDrop(
    () => ({
      accept: EVapePiece.BATTERY,
      drop: () => {
        setBatteryPos([x + 1, y + 100]);
        setBatteryDropped(true);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [x, y]
  );
  const [, powerIndicatorRef] = useDrop(
    () => ({
      accept: EVapePiece.POWER_INDICATOR,
      drop: () => {
        setPowerPos([x + 1, y + 321]);
        setPowerDropped(true);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [x, y]
  );

  const MobileDragPreview = () => {
    const { display, item, style } = usePreview() as any;
    if (!display) {
      return null;
    }
    return (
      <div className="activity-vape-puzzle__drag" style={style}>
        <img
          className="activity-vape-puzzle__drag-icon"
          alt="pièce en mouvement"
          src={vapeInteraction.getVapePieceByName(item.id)}
        />
      </div>
    );
  };

  if (podIsDrop && batteryIsDrop && dripIsDrop && powerIsDrop) {
    window?.setTimeout((): void => {
      onFinishPuzzleEvt();
    }, 2500);
  }

  return (
    <div className="activity-vape-puzzle">
      <div className="activity-vape-puzzle__container">
        <div className="activity-vape-puzzle__vape-container">
          <MobileDragPreview />
          <div
            className="activity-vape-puzzle__piece"
            ref={dripTipPieceRef}
            style={{ top: dripPos[1], left: dripPos[0], opacity: isDraggingDrip ? 0.5 : 1 }}
          >
            <img alt="drip tip" className="activity-vape-puzzle__dripTip" src={VapoteuseDripTipSVG} />
          </div>
          <div
            className="activity-vape-puzzle__piece"
            ref={podPieceRef}
            style={{ top: podPos[1], left: podPos[0], opacity: isDraggingPod ? 0.5 : 1 }}
          >
            <img alt="drip tip" className="activity-vape-puzzle__podRef" src={VapoteuseLiquidPODSVG} />
          </div>
          <div
            className="activity-vape-puzzle__piece"
            ref={batteryPieceRef}
            style={{ top: batteryPos[1], left: batteryPos[0], opacity: isDraggingBattery ? 0.5 : 1 }}
          >
            <img alt="drip tip" className="activity-vape-puzzle__battery" src={VapoteuseBatterieSVG} />
          </div>
          <div
            className="activity-vape-puzzle__piece"
            ref={powerPieceRef}
            style={{ top: powerPos[1], left: powerPos[0], opacity: isDraggingPower ? 0.5 : 1 }}
          >
            <img alt="drip tip" className="activity-vape-puzzle__power-indicator" src={VapoteusePowerIndicatorSVG} />
          </div>
          <div className="activity-vape-puzzle__vape-wrapper" ref={vapeWrapper}>
            <SVGLoader className="activity-vape-puzzle__blank-vape" src={VapoteuseBlankSVG} />
            <div ref={dripTipRef} className={`activity-vape-puzzle__vape-p1 ${isDraggingDrip && '--is-dragging'}`} />
            <div ref={liquidPodRef} className={`activity-vape-puzzle__vape-p2 ${isDraggingPod && '--is-dragging'}`} />
            <div ref={batteryRef} className={`activity-vape-puzzle__vape-p3 ${isDraggingBattery && '--is-dragging'}`} />
            <div
              ref={powerIndicatorRef}
              className={`activity-vape-puzzle__vape-p4 ${isDraggingPower && '--is-dragging'}`}
            />
          </div>
        </div>
        {isMusicEnabled && podIsDrop && <H5AudioPlayer src={ClickVapoteuseMP3} autoPlay />}
        {isMusicEnabled && dripIsDrop && <H5AudioPlayer src={ClickVapoteuseMP3} autoPlay />}
        {isMusicEnabled && powerIsDrop && <H5AudioPlayer src={ClickVapoteuseMP3} autoPlay />}
        {isMusicEnabled && batteryIsDrop && <H5AudioPlayer src={ClickVapoteuseMP3} autoPlay />}
        <VapePieceInformation
          isDripDragging={isDraggingDrip}
          isPodDragging={isDraggingPod}
          isBatteryDragging={isDraggingBattery}
          isPowerDragging={isDraggingPower}
        />
      </div>
    </div>
  );
};
export default ActivityVapePuzzle;
