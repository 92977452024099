import React from 'react';
import content from './content.json';
import { EConsumption } from 'enum/user';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import { CercleStressSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import './ScreenFreezerComplete.scss';

type Content = {
  description: string;
};

const ScreenFreezerComplete = (): React.ReactElement => {
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const { description }: Content = userConsumptionType === EConsumption.VAPER ? content.vaper : content.smoker;

  return (
    <div className="screen-freezer-complete">
      <ActivitySplashScreen
        title="Ouff, as-tu ressenti du stress?"
        description={description}
        imageSrc={CercleStressSVG}
      />
      <ActivitySplashAction
        onContinueButton={() => sceneInteraction.goToBarScreen(true)}
        onBackButton={() => sceneInteraction.goToPreviousScreen()}
      />
    </div>
  );
};
export default ScreenFreezerComplete;
