import React, { useState } from 'react';

import ActivityFridge from 'component/activity-fridge/ActivityFridge';
import { FrigoSVG } from 'services/exports-list';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import sceneInteraction from 'services/scene-interaction';
import googleTagManager from 'services/google-tag-manager';

import './ScreenFridge.scss';

/**
 * The main screen to display for the locked fridge activity
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenFridge = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState(true);
  const [isActionEnabled, setActionEnabled] = useState(false);
  const [isSuccessful, setisSuccessful] = useState(false);
  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen) {
      sceneInteraction.goToNextScreen();
    } else {
      setHasSplashScreen(false);
    }
  };
  const goToPrevStep: () => void = (): void => {
    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(true);
    }
  };
  const onFinish = (timeLeft: number): void => {
    if (timeLeft <= 0) {
      googleTagManager.onFreezerActivityComplete(false);
      setActionEnabled(true);
      sceneInteraction.goToNextScreen();
    } else {
      setisSuccessful(true);
      googleTagManager.onFreezerActivityComplete(true);
      window?.setTimeout(() => {
        sceneInteraction.goToNextScreen();
      }, 3000);
    }
  };
  return (
    <div className="screen-fridge">
      {isSplashScreen ? (
        <ActivitySplashScreen
          title="Mission remplissage!"
          description="Les clients en redemandent et Nico, ton barman préféré, a besoin de ton aide pour aller chercher d’autres ingrédients dans la réserve ! Trouve la combinaison du cadenas pour accéder aux ingrédients dans le frigo. Vite, tu n’as que 20 secondes!"
          imageSrc={FrigoSVG}
        />
      ) : (
        <ActivityFridge isSuccessful={isSuccessful} onFinish={(timeLeft: number) => onFinish(timeLeft)} />
      )}
      <ActivitySplashAction
        isContinueDisabled={!isSplashScreen && !isActionEnabled}
        onContinueButton={() => goToNextStep()}
        onBackButton={() => goToPrevStep()}
      />
    </div>
  );
};
export default ScreenFridge;
