import React, { useState } from 'react';
import toysInteraction from 'services/toys-interaction';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import { MachineToutousCompleteSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import ActivityToysMachine from 'component/activity-toys-machine/ActivityToysMachine';
import './ScreenToysMachine.scss';

/**
 * The main screen to display for the cocktail list activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenToysMachine = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState(true);
  const [isActionEnabled, setActionEnabled] = useState(false);

  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen) {
      setActionEnabled(false);
      sceneInteraction.goToBarScreen(true);
      toysInteraction.setStepNumberToys(3);
      toysInteraction.setFinalStepNumberToys(0);
      toysInteraction.setDropState(false);
      toysInteraction.setCraneIsDown(false);
    } else {
      setHasSplashScreen(false);
    }
  };
  const goToPrevStep: () => void = (): void => {
    toysInteraction.setStepNumberToys(3);
    toysInteraction.setFinalStepNumberToys(0);
    toysInteraction.setDropState(false);
    toysInteraction.setCraneIsDown(false);

    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      if (!isActionEnabled) {
        setHasSplashScreen(true);
      }
      setActionEnabled(false);
    }
  };
  return (
    <div className="screen-cocktail">
      {isSplashScreen ? (
        <ActivitySplashScreen
          title="Es-tu tou-tourmenté?"
          description="Wow, la belle machine à toutous! C'est ton jour de chance…ou pas! Attrape un toutou pour savoir quel est le p'tit souvenir que tu vas rapporter avec toi suite à ta visite au Nico-Bar. Des montagnes russes d'émotions! Sélectionne ton toutou à l'aide des flèches et du bouton rouge."
          imageSrc={MachineToutousCompleteSVG}
        />
      ) : (
        <ActivityToysMachine onResultEvt={() => setActionEnabled(true)} />
      )}
      <ActivitySplashAction
        continueButtonLabel={isSplashScreen ? 'Attrape un toutou' : 'Continuer'}
        backButtonLabel={isActionEnabled ? 'Recommencer' : 'Revenir'}
        isContinueDisabled={!isSplashScreen && !isActionEnabled}
        onContinueButton={() => goToNextStep()}
        onBackButton={() => goToPrevStep()}
      />
    </div>
  );
};
export default ScreenToysMachine;
