import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import H5AudioPlayer from 'react-h5-audio-player';
import { ECocktaiIngredientlList } from 'enum/cocktail';
import SVGLoader from 'component/svg/SVG';
import { BarBarmanShakingSVG, BartenderShakingMP3 } from 'services/exports-list';
import cocktailInteraction from 'services/cocktail-interaction';
import './CocktailResult.scss';
import sceneInteraction from 'services/scene-interaction';

interface IProps {
  ingredientList: Array<ECocktaiIngredientlList>;
}

/**
 * Generate the user cocktail base on the first ingredient.
 * @param ingredient: ECocktaiIngredientlList - required - The name of the first ingredient.
 * @returns React.ReactElement
 */
const CocktailResult = ({ ingredientList }: IProps): React.ReactElement => {
  const isMusicEnabled = sceneInteraction.isMusicEnabled();
  const [isResultDisplay, toggleResultDisplay] = useState<boolean>(false);
  const { title, description, image } = cocktailInteraction.getCocktailByIngredient(ingredientList[0]);

  // Add a timer for the barman preparing the dring animation
  useEffect(() => {
    window?.setTimeout((): void => {
      toggleResultDisplay(true);
    }, 4000);
  }, []);

  return (
    <div className="cocktail-result">
      <SwitchTransition>
        <CSSTransition
          key={`isDisplayed-${isResultDisplay}`}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          {!isResultDisplay ? (
            <div className="cocktail-result__shaking-anim">
              {isMusicEnabled && <H5AudioPlayer src={BartenderShakingMP3} autoPlay />}
              <p className="cocktail-result__shaking-title">
                Nico le barman prépare un cocktail signature selon les ingrédients que tu as choisis.
              </p>
              <SVGLoader src={BarBarmanShakingSVG} className="cocktail-result__barman" />
            </div>
          ) : (
            <div className="cocktail-result__display">
              <div className="cocktail-result__cocktail-heading">
                <SVGLoader className="cocktail-result__cocktail-name" src={title} />
              </div>
              <div className="cocktail-result__cocktail">
                <SVGLoader className="cocktail-result__cocktail-image" src={image} />
                <div className="cocktail-result__cocktail-information">
                  <p>{description}</p>
                  <ul>
                    {ingredientList.map((ingredient) => {
                      return (
                        <li
                          dangerouslySetInnerHTML={{ __html: cocktailInteraction.getIngredientWarning(ingredient) }}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
export default CocktailResult;
