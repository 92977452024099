import React, { useState } from 'react';

import content from './content.json';
import { EConsumption } from 'enum/user';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import ActivityMirrorAnimation from 'component/activity-mirror-animation/ActivityMirrorAnimation';
import { PetitMiroirSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import './ScreenMirror.scss';

type Content = {
  description: string;
};

/**
 * The main screen to display for the mirror activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenMirror = (): React.ReactElement => {
  const [isSplashScreen, setHasSplashScreen] = useState<boolean>(true);
  const [isInsideMirrorActivity, setIsInsideMirrorActivity] = useState<boolean>(false);
  const [isContinueDisabled, setToggleContinue] = useState<boolean>(false);
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const { description }: Content = userConsumptionType === EConsumption.VAPER ? content.vaper : content.smoker;

  const goToNextStep: () => void = (): void => {
    if (!isSplashScreen && !isInsideMirrorActivity) {
      sceneInteraction.goToBarScreen(true);
    } else if (isInsideMirrorActivity) {
      setIsInsideMirrorActivity(false);
    } else {
      setHasSplashScreen(false);
      setIsInsideMirrorActivity(true);
      setToggleContinue(true);
      window?.setTimeout(() => {
        setToggleContinue(false);
      }, 16000);
    }
  };
  const goToPrevStep: () => void = (): void => {
    if (isSplashScreen) {
      sceneInteraction.goToBarScreen(true);
    } else {
      setHasSplashScreen(true);
    }
  };
  return (
    <div className="screen-mirror">
      {isSplashScreen ? (
        <ActivitySplashScreen title="Miroir, Miroir, dis-moi…" description={description} imageSrc={PetitMiroirSVG} />
      ) : (
        <ActivityMirrorAnimation isAfterMirror={!isInsideMirrorActivity} />
      )}
      <ActivitySplashAction
        isContinueDisabled={isContinueDisabled}
        onContinueButton={() => !isContinueDisabled && goToNextStep()}
        onBackButton={() => goToPrevStep()}
      />
    </div>
  );
};
export default ScreenMirror;
