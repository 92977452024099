import userInteraction from './user-interaction';
import { EBarScene, EScene } from 'enum/scene';
import { IUserData } from 'interface/i-user';
import { EGTMEvent, EGTMProfile, EGTMScene } from 'enum/gtm';
import { EConsumption } from 'enum/user';

declare global {
  interface Window {
    dataLayer: any;
  }
}
const dataLayer = window?.dataLayer;

/**
 * Create a service class to call GoogleTagManager
 * */
class GoogleTagManager {
  dataLayerPush(params: any): void {
    dataLayer.push(params);
  }

  /**
   * Create a event to send analytic on scene change.
   * @param currentSceneName EScene | EBarScene: The next scene name
   */
  onBarSceneUpdate(currentSceneName: EScene | EBarScene): void {
    this.dataLayerPush({
      event: `${this.getProfileGTM()}${EGTMEvent.GO_TO_SCENE}${EGTMScene[currentSceneName] || ''}`,
      scene_name: currentSceneName,
    });
  }

  getProfileGTM(): string {
    const userData: IUserData = userInteraction.getUserData();
    switch (userData.consumption) {
      case EConsumption.SMOKER:
        return EGTMProfile.SMOKER;
      case EConsumption.VAPER:
        return EGTMProfile.VAPER;
      case EConsumption.OTHERS:
        return EGTMProfile.OTHERS;
      default:
        return EGTMProfile.OTHERS;
    }
  }

  /**
   * Create an event to send an analytic on user registration
   */
  onUserDataSubmit(): void {
    const userData: IUserData = userInteraction.getUserData();

    let eventStart: string = '';
    if (userData.consumption === EConsumption.VAPER) {
      eventStart = EGTMEvent.PROFIL_VAPE_DEMARRER;
    } else if (userData.consumption === EConsumption.SMOKER) {
      eventStart = EGTMEvent.PROFIL_FUM_DEMARRER;
    } else {
      eventStart = EGTMEvent.PROFIL_ACCOMP_DEMARRER;
    }

    this.dataLayerPush({
      event: eventStart,
      ...userData,
    });
  }

  /**
   * When the freezer activity is finish
   * @param hasSucceded: boolean - Either the event has been completed or failed
   */
  onFreezerActivityComplete(hasSucceded: boolean = false): void {
    this.dataLayerPush({
      event: `${this.getProfileGTM()}${EGTMEvent.FREEZER_COMPLETE}`,
      hasSucceded,
    });
  }

  /**
   * Send data when the click someone click on the challenge.
   * @param origin: string - The location of the click
   */
  onClickOnChallenge(origin: string): void {
    this.dataLayerPush({
      event: `${this.getProfileGTM()}${EGTMEvent.CLICK_CHALLENGE}`,
      origin,
    });
  }

  /**
   * Send analytic when the user choose to give access to his own camera.
   * @param isGranted: boolean - Either the camera is granted access or not
   */
  onCameraPermission(isGranted: boolean): void {
    this.dataLayerPush({
      event: `${this.getProfileGTM()}${EGTMEvent.CAMERA_PERMISSION}`,
      isGranted,
    });
  }

  /**
   * Send analytic when the user click to share quizz results on facebook.
   */
  onShareOnFB(): void {
    this.dataLayerPush({
      event: `${this.getProfileGTM()}${EGTMEvent.FACEBOOK_SHARING}`,
    });
  }

  /**
   * Send analytic when the user click to results on cocktail.
   */
  onFinishCocktail(): void {
    this.dataLayerPush({
      event: `${this.getProfileGTM()}Pres_cocktail`,
    });
  }

  /**
   * Send analytic when the user complete scene.
   */
  onCompleteScene(currentSceneName: EScene | EBarScene): void {
    let sceneComplete: string = '';
    switch (currentSceneName) {
      case EScene.MIRROR:
        sceneComplete = 'Filtre_complété';
        break;
      case EScene.FREEZER:
      case EScene.FREEZER_COMPLETE:
        sceneComplete = 'Frigo_complété';
        break;
      case EScene.VAPE_PUZZLE:
        sceneComplete = `${EGTMScene[currentSceneName] || ''}'_vape_complété'`;
        break;
      default:
        sceneComplete = `${EGTMScene[currentSceneName] || ''}'_complété'`;
    }

    this.dataLayerPush({
      event: `${this.getProfileGTM()}${sceneComplete}`,
    });
  }

  /**
   * Send analytic when the user click to restart profile.
   */
  onRestartProfile(): void {
    this.dataLayerPush({
      event: 'Clic_parcours2',
    });
  }
}

export default new GoogleTagManager();
