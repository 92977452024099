export enum EGTMEvent {
  GO_TO_SCENE = 'Voir_',
  REGISTER = 'register',
  FREEZER_COMPLETE = 'freezer_is_complete',
  CLICK_CHALLENGE = 'Clic_Defi',
  CAMERA_PERMISSION = 'camera_granted',
  FACEBOOK_SHARING = 'quiz_partage',
  PROFIL_VAPE_DEMARRER = 'Profil_vape_démarrer',
  PROFIL_FUM_DEMARRER = 'Profil_fum_démarrer',
  PROFIL_ACCOMP_DEMARRER = 'Profil_accomp_démarrer',
}

export enum EGTMProfile {
  VAPER = 'Vap_',
  OTHERS = 'Acc_',
  SMOKER = 'Fum_',
}

export enum EGTMScene {
  START = 'start',
  USER_REGISTRATION = 'registration',
  INSIDE_BAR = 'inside-the-bar',
  COCKTAIL = 'cocktail',
  TOYS_MACHINE = 'toutous',
  MIRROR = 'toilettes',
  FREEZER = 'Frigo',
  FREEZER_COMPLETE = 'on-freezer-complete',
  VAPE_PUZZLE = 'casse-tete',
  QUIZZ = 'quiz',
  CONTEST = 'concour',
  END = 'END',
}
