import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sceneInteraction from 'services/scene-interaction';
import { SingleLogoSVG, SurveyLogoSVG } from 'services/exports-list';
import googleTagManager from 'services/google-tag-manager';
import SVGLoader from 'component/svg/SVG';
import Button from 'component/button/Button';

import './ActionBar.scss';

interface IProps {
  isMusicPlaying: boolean;
}

const today = new Date().getTime();
const validUntil = new Date('2024-03-07').getTime();
const isSurvey: boolean = process.env.REACT_APP_ENV === 'survey' && validUntil > today;
const isVirtual: boolean = process.env.REACT_APP_ENV === 'virtual';
/**
 * Component that will display the "exit" and "mute" button
 * @returns React.ReactElement
 */

export const ActionBar = ({ isMusicPlaying }: IProps): React.ReactElement => (
  <div className="action-bar">
    {isSurvey && (
      <a
        className="action-bar__logo-and-survey"
        href={process.env.REACT_APP_NICOBAR_SURVEY_URL}
        target="_blank"
        rel="noreferrer"
        onClick={() => googleTagManager.onClickOnChallenge('header')}
        aria-label="Vous allez être rediriger vers le sondage"
      >
        <SVGLoader src={SurveyLogoSVG} />
        <span className="action-bar__exit-button">
          <FontAwesomeIcon icon={['fas', 'sign-out']} />
        </span>
      </a>
    )}

    {!isVirtual && (
      <a
        className="action-bar__logo-and-exit"
        href={process.env.REACT_APP_NICOBAR_MANY_CHAT_URL}
        target="_blank"
        rel="noreferrer"
        onClick={() => googleTagManager.onClickOnChallenge('header')}
        aria-label="Vous allez être rediriger sur le site du nicobar"
      >
        <SVGLoader src={SingleLogoSVG} />
        <span className="action-bar__exit-button">
          <FontAwesomeIcon icon={['fas', 'sign-out']} />
        </span>
      </a>
    )}

    <div className="action-bar__music">
      <Button onClick={() => sceneInteraction.toggleMusicPlaying(!isMusicPlaying)} className="action-bar__music-button">
        <FontAwesomeIcon icon={['fas', isMusicPlaying ? 'volume-up' : 'volume-mute']} />
      </Button>
    </div>
  </div>
);

export default ActionBar;
