import { debounce } from 'lodash';
import googleTagManager from './google-tag-manager';
import {
  changeBarScene,
  changeBarVapeScene,
  changeMusic,
  changeMusicState,
  changeScene,
  completeBarScene,
  completeProfile,
} from 'action/scene-action';
import { EBarScene, EBarSceneVape, EMusicScene, EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';
import { IReduxState } from 'interface/i-redux-state';
import store from 'store/Store';
import userInteraction from 'services/user-interaction';

class SceneInteraction {
  constructor() {
    this.goToNextScreen = this.goToNextScreen.bind(this);
    this.goToPreviousScreen = this.goToPreviousScreen.bind(this);
    this.goToNextBarScreen = this.goToNextBarScreen.bind(this);
    this.goToPreviousBarScreen = this.goToPreviousBarScreen.bind(this);
    this.getBarScene = this.getBarScene.bind(this);
  }

  /**
   * storeDispatch
   * Create a Redux Dispatch to the main store with a debounce
   * Use only inside this service
   * @param action: any - The action you would normally send to the store via a dispatch
   */
  public storeDispatch(action: any): void {
    const debounceFunc: () => void = debounce(() => {
      store.dispatch(action);
    }, 250);
    debounceFunc();
  }

  /**
   * goToNextScreen
   * Change the state of the `currentScreen` variable from the redux store
   * Display the next screen
   */
  goToNextScreen(): void {
    const state: IReduxState = store.getState();
    const sceneList: Array<string> = Object.keys(EScene);
    const currentIndex: number = sceneList.indexOf(state.currentScene);
    if (state.currentScene === EScene.USER_REGISTRATION) {
      googleTagManager.onUserDataSubmit();
    }
    if (sceneList.length && sceneList.length > currentIndex) {
      const nextScene: string = sceneList[currentIndex + 1];
      switch (nextScene) {
        case EScene.INSIDE_BAR:
          this.goToMusic(EMusicScene.INSIDE);
          break;
        case EScene.COCKTAIL:
        case EScene.FREEZER:
        case EScene.MIRROR:
          this.goToMusic(EMusicScene.ACTIVITY);
          break;
        case EScene.QUIZZ:
          this.goToMusic(EMusicScene.QUIZZ);
          break;
        default:
          break;
      }
      googleTagManager.onBarSceneUpdate(nextScene as EScene);
      this.storeDispatch(changeScene(nextScene as EScene));
    }
  }

  /**
   * goToPreviousScreen
   * Change the state of the `currentScreen` variable from the redux store
   * Display the previous screen
   */
  goToPreviousScreen(): void {
    const state: IReduxState = store.getState();
    const sceneList: Array<string> = Object.keys(EScene);
    const currentIndex: number = sceneList.indexOf(state.currentScene);
    if (sceneList.length && currentIndex > 0) {
      const nextScene: string = sceneList[currentIndex - 1];
      switch (nextScene) {
        case EScene.USER_REGISTRATION:
          this.goToMusic(EMusicScene.OUTSIDE);
          break;
        case EScene.INSIDE_BAR:
          this.goToMusic(EMusicScene.INSIDE);
          break;
        case EScene.COCKTAIL:
        case EScene.FREEZER:
        case EScene.MIRROR:
          this.goToMusic(EMusicScene.ACTIVITY);
          break;
        case EScene.QUIZZ:
          this.goToMusic(EMusicScene.QUIZZ);
          break;
        default:
          break;
      }
      googleTagManager.onBarSceneUpdate(nextScene as EScene);
      this.storeDispatch(changeScene(nextScene as EScene));
    }
  }

  /**
   * goToNextScreen
   * Change the state of the `currentScreen` variable of the redux store
   * @param screenName
   */
  goToScreen(screenName: EScene | EBarScene): void {
    googleTagManager.onBarSceneUpdate(screenName as EScene);
    switch (screenName) {
      case EScene.COCKTAIL:
      case EScene.FREEZER:
      case EScene.MIRROR:
        this.goToMusic(EMusicScene.ACTIVITY);
        break;
      case EScene.QUIZZ:
        this.goToMusic(EMusicScene.QUIZZ);
        break;
      default:
        break;
    }
    this.storeDispatch(changeScene(screenName as EScene));
  }

  /**
   * goToBarScreen
   * Change the state of the `currentScreen` variable to always go back to the bar
   * Display the bar
   * @param goToNextBarScreen: boolean - Change the redux state to go to the next bar screen (for animation purpose)
   */

  goToBarScreen(goToNextBarScreen: boolean = false) {
    const state: IReduxState = store.getState();
    this.storeDispatch(completeBarScene(state.currentScene));
    googleTagManager.onCompleteScene(state.currentScene);
    if (state.currentScene === EScene.FREEZER_COMPLETE) {
      this.storeDispatch(completeBarScene(EScene.FREEZER));
    }
    this.storeDispatch(changeScene(EScene.INSIDE_BAR));
    this.goToMusic(EMusicScene.INSIDE);
    if (goToNextBarScreen) {
      setTimeout(() => {
        this.goToNextBarScreen();
      }, 250);
    }
  }

  /**
   * goToNextBarScreen
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the next screen
   */
  goToNextBarScreen(): void {
    const state: IReduxState = store.getState();

    if (state.consumption === EConsumption.VAPER) {
      this.goToNextVapeSceneNotComplete();
    } else {
      this.goToNextSmokeSceneNotComplete();
    }
  }

  /**
   * goToPreviousBarScreen
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToPreviousBarScreen(): void {
    const state: IReduxState = store.getState();

    if (state.consumption === EConsumption.VAPER) {
      this.goToNextVapeSceneNotComplete();
    } else {
      this.goToNextSmokeSceneNotComplete();
    }
  }

  /**
   * goToNextVapeSceneNotComplete
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToNextVapeSceneNotComplete(): void {
    const barScene: EScene = this.getBarScene();
    const completedScene: EScene[] = this.getCompletedScene();
    const sceneVapeList: Array<string> = Object.keys(EBarSceneVape);
    const currentIndex: number = sceneVapeList.indexOf(barScene);

    if (sceneVapeList.length && sceneVapeList.length > currentIndex) {
      let index: number = 0;
      while (index < sceneVapeList.length - 1) {
        index += 1;

        if (!completedScene.includes(sceneVapeList[index] as EScene)) {
          this.storeDispatch(changeBarVapeScene(sceneVapeList[index] as EBarSceneVape));
          break;
        }
      }
    }
  }

  /**
   * goToNextSmokeSceneNotComplete
   * Change the state of the `currentBarScreen` variable from the redux store
   * Display the previous screen
   */
  goToNextSmokeSceneNotComplete(): void {
    const barScene: EScene = this.getBarScene();
    const completedScene: EScene[] = this.getCompletedScene();
    const sceneList: Array<string> = Object.keys(EBarScene);
    const currentIndex: number = sceneList.indexOf(barScene);

    if (sceneList.length && sceneList.length > currentIndex) {
      let index: number = 0;
      while (index < sceneList.length - 1) {
        index += 1;
        if (!completedScene.includes(sceneList[index] as EScene)) {
          this.storeDispatch(changeBarScene(sceneList[index] as EBarScene));
          break;
        }
      }
    }
  }

  /**
   * goToRestartProfile
   * Restart the profile with the opposite of the current profile
   */
  goToRestartProfile(): void {
    const state: IReduxState = store.getState();

    googleTagManager.onRestartProfile();
    this.storeDispatch(completeProfile(state.consumption));
    if (state.consumption === EConsumption.VAPER) {
      userInteraction.resetProfileSmoke();
    } else {
      userInteraction.resetProfileVape();
    }
  }

  /**
   * getBarScene
   * Get the current bar scene name
   * @return EBarScene
   */
  getBarScene(): EScene {
    const state: IReduxState = store.getState();
    return state.currentBarScene;
  }

  /**
   * getCompletedScene
   * Get the list of the completed activity
   * @return EScene[]
   */
  getCompletedScene(): EScene[] {
    const state: IReduxState = store.getState();
    return state.completedBarScene;
  }

  /**
   * goToMusic
   * Change the music base on the current scene
   * @param music: EMusicScene - Name of the enum for the music.
   * @return void
   */
  goToMusic(music: EMusicScene): void {
    this.storeDispatch(changeMusic(music));
  }

  /**
   * toggleMusicPlaying
   * Change the state of the music.
   * @param hasSoundEnabled: boolean - Can play music.
   * @return void
   */
  toggleMusicPlaying(hasSoundEnabled: boolean): void {
    this.storeDispatch(changeMusicState(hasSoundEnabled));
  }

  /**
   * isMusicEnabled
   * Get if the sound is currently on.
   * @return boolean
   */
  isMusicEnabled(): boolean {
    const state: IReduxState = store.getState();
    return state.isMusicPlaying;
  }
}

export default new SceneInteraction();
